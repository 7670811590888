import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import ShareLinks from './ShareLinks'
import {GatsbyImage} from "gatsby-plugin-image"
import moment from 'moment'

class StrapiNewsCard extends React.Component { 
  constructor(props) {
    super()

    this.post = props.post
    this.state = {
      dropdownState: false,
    }

    this.toggleShare = this.toggleShare.bind(this)
  }

  toggleShare() {
    this.setState({dropdownState: !this.state.dropdownState})
  }

  slugify(text) {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
    const to = "aaaaaeeeeeiiiiooooouuuunc------"

    const newText = text.split('').map(
      (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

    return newText
      .toString()                     // Cast to string
      .toLowerCase()                  // Convert the string to lowercase letters
      .normalize('NFD')               // The normalize() method returns the Unicode Normalization Form of a given string.
      .trim()                         // Remove whitespace from both sides of a string
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/&/g, '-y-')           // Replace & with 'and'
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-');        // Replace multiple - with single -
  }

  render() {
    let post = this.post
    let slug = this.slugify(post.title)
    let date = moment(post.publishedAt).format("YYYY-MM-DD")
    let date_formatted = moment(post.publishedAt).format("MMMM DD, YYYY")
    let path = `/blog/${date}-${slug}`
    let hasExternal = post.externalLink !== null



  
  return (
    <>
    { hasExternal ? (
      <a className="news-preview-item" key={post.id} href={post.externalLink}>
        <GatsbyImage image={post.heroImage.localFile.childImageSharp.gatsbyImageData} alt="" style={{ width: '100%', height: '280px', borderRadius: '2px' }} />
        <div className="news-title">
          <h1 className="title">{post.title}</h1>
          <p><small className="accent-color">Posted: {date_formatted}</small></p>
          <a className="button is-white is-rounded" href={post.externalLink}>Read More</a>
        </div>
      </a>
    ): (
      <Link className="news-preview-item" key={post.id} to={path}>
        <GatsbyImage image={post.heroImage.localFile.childImageSharp.gatsbyImageData} alt="" style={{ width: '100%', height: '280px', borderRadius: '2px' }} />
        <div className="news-title">
          <h1 className="title">{post.title}</h1>
          <p><small className="accent-color">Posted: {date_formatted}</small></p>
          <Link className="button is-white is-rounded" to={path}>Read More</Link>
        </div>
      </Link>
    )}
  </>
)
}
}

export default StrapiNewsCard
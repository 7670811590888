import React from 'react'
import PropTypes from 'prop-types'
import Content from './Content'
import NewsCard from './NewsCard'
import StrapiNewsCard from './StrapiNewsCard'


const BillPageTemplate = ({ title, content, contentComponent, posts, strapiPosts }) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section section--gradient main-section-container">
      <div className="content-spacer"></div>
      <div className="content container">
            <div className="section">
              <h1 className="title is-size-1 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <PageContent className="content" content={content} />
            </div>
            <div className="news-section news-full-grid">
              {strapiPosts.map(strapiPost =>(
                <StrapiNewsCard post={strapiPost} />
              ))}
              {posts.map(({ node: post }) =>(
                <NewsCard key={post.id} post={post} />
              ))}
              </div>
            </div>
    </section>
  )
}

BillPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default BillPageTemplate
